import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import LayoutNoFullHeight from "../components/interactive-space/layout-no-full-height"
import { StaticImage } from "gatsby-plugin-image"

const FotosActivistas = ({ data }) => {

  const dataFragments = data.dataJson

  return(
    <LayoutNoFullHeight bgImage="false">
      <Box
        as={`main`}
        sx={{
          width: `100%`,
          paddingTop: 3,
          paddingRight: 4,
          paddingLeft: 4,
          "h1, h2, p, ul, li, a, figcaption": {
            color: `gray.6`,
          },
          figure: {
            margin: 0,
          },
          figcaption: {
            variant: `text.base`,
            color: `gray.6`,
          },
          ".activists-container": {
            flexWrap: `wrap`,
            figure: {
              width: [`100vw`, `40vw`, `40vw`, `30vw`, `30vw`, `23vw`],
              marginBottom: 3,
              marginRight: 3,
            }
          }
        }}
      >
        <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
        <Themed.p>{dataFragments.fragment_2}</Themed.p>
        <Themed.h2>{dataFragments.fragment_5}</Themed.h2>
        <Flex className="activists-container">
          <figure>
            <StaticImage src="../images/guatemala/andrea_ixchiu_00.jpg" alt="andrea ixchiu" />
            <figcaption>Andrea Ixchiu</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/aparicio_perez_00.jpg" alt="aparicio perez" />
            <figcaption>Aparicio Pérez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/bernardo_caal_xol_00.jpg" alt="bernardo caal xol" />
            <figcaption>Bernardo Caal Xol</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/carolina_rax_00.jpg" alt="carolina rax" />
            <figcaption>Carolina Rax</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/cesar_augusto_elias_00.jpg" alt="cesar augusto elias" />
            <figcaption>César Augusto Elías</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/claudia_samaoya_00.jpg" alt="claudia samaoya" />
            <figcaption>Claudia Samayoa</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/comunidad_de_ixquisis_00.jpg" alt="comunidad de ixquisis" />
            <figcaption>Comunidad de Ixquisis</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/edgar_perez_archila_00.jpg" alt="edgar perez archila" />
            <figcaption>Édgar Pérez Archila</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/esteban_hermelindo_cux_choc_00.jpg" alt="esteban hermelindo cux choc" />
            <figcaption>Esteban Hermelindo Cux Choc</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/felisa_muralleis_00.jpg" alt="felisa muralleis" />
            <figcaption>Felisa Muralleis</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/lorena_cabnal_00.jpg" alt="lorena cabnal" />
            <figcaption>Lorena Cabnal</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/marcela_chacach_00.jpg" alt="marcela chacach" />
            <figcaption>Marcelona Chacach</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/marcelo_sabuc_00.jpg" alt="marcelo sabuc" />
            <figcaption>Marcelo Sabuc</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/mauro_vay_gonon_00.jpg" alt="mauro vay gonon" />
            <figcaption>Mauro Vay Gonón</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/rosa_coy_pou_00.jpg" alt="rosa coy pou" />
            <figcaption>Rosa Coy Pou</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/rosa_maria_sub_choc_00.jpg" alt="rosa maria sub choc" />
            <figcaption>Rosa María Sub Choc</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/rosalina_tuyuc_00.jpg" alt="rosalina tuyuc" />
            <figcaption>Rosalina Tuyuc</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/rosalina_tuyuc_01.jpg" alt="rosalina tuyuc" />
            <figcaption>Rosalina Tuyuc</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/rutilia_ical_00.jpg" alt="rutilia ical" />
            <figcaption>Rutilia Ical</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/guatemala/demecia_yat_00.jpg" alt="demecia yat"  />
            <figcaption>Demecia Yat</figcaption>
          </figure>
        </Flex>

        <Themed.h2>{dataFragments.fragment_6}</Themed.h2>
        <Flex className="activists-container">
          <figure>
            <StaticImage src="../images/honduras/albertina_lopez_00.jpg" alt="albertina_lopez" />
            <figcaption>Albertina López</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/armando_castillo_00.jpg" alt="armando_castillo" />
            <figcaption>Armando Castillo</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/berta_zuniga_caceres_00.jpg" alt="bertha zuniga caceres" />
            <figcaption>Bertha Zuñiga Cáceres</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/carmen_alvarez_00.jpg" alt="carmen_alvarez" />
            <figcaption>Carmen Álvarez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/cesar_benedit_00.jpg" alt="cesar_benedit" />
            <figcaption>César Benedit</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/comunidad_indigena_lenca_00.jpg" alt="comunidad_indigena_lenca" />
            <figcaption>Comunidad indígena Lenca</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/consuelo_soto_00.jpg" alt="consuelo_soto" />
            <figcaption>Consuelo Soto</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/consuelo_soto_01.jpg" alt="consuelo_soto" />
            <figcaption>Consuelo Soto</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/edy_tabora_00.jpg" alt="edy_tabora" />
            <figcaption>Edy Tábora</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/gaspar_sanchez_00.jpg" alt="gaspar_sanchez" />
            <figcaption>Gaspar Sánchez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/gaspar_sanchez_01.jpg" alt="gaspar_sanchez" />
            <figcaption>Gaspar Sánchez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/gilda_rivera_00.jpg" alt="gilda_rivera" />
            <figcaption>Gilda Rivera</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ismael_moreno_coto_00.jpg" alt="ismael_moreno_coto" />
            <figcaption>Ismael Moreno Coto</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ismael_moreno_coto_01.jpg" alt="ismael_moreno_coto" />
            <figcaption>Ismael Moreno Coto</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ismael_moreno_coto_02.jpg" alt="ismael_moreno_coto" />
            <figcaption>Ismael Moreno Coto</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/jessica_trinidad_00.jpg" alt="jessica_trinidad" />
            <figcaption>Jessica Trinidad</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/karla_lara_00.jpg" alt="karla_lara" />
            <figcaption>Karla Lara</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/lucila_lemus_julio_leiva_elena_gaytan_00.jpg" alt="lucila_lemus_julio_leiva_elena_gaytan" />
            <figcaption>Lucila Lemus, Julio Leiva, Elena Gaytán</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/lucila_lemus_julio_leiva_elena_gaytan_01.jpg" alt="lucila_lemus_julio_leiva_elena_gaytan" />
            <figcaption>Lucila Lemus, Julio Leiva, Elena Gaytán</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/malbin_norales_00.jpg" alt="malbin_norales" />
            <figcaption>Malbin Norales</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/martin_fernandez_00.jpg" alt="martin_fernandez" />
            <figcaption>Martín Fernández</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/miriam_miranda_00.jpg" alt="miriam_miranda" />
            <figcaption>Miriam Miranda</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ramon_santiago_matute_avila_00.jpg" alt="ramon_santiago_matute_avila" />
            <figcaption>Ramón Santiago Matute Ávila</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ramon_santiago_matute_avila_01.jpg" alt="ramon_santiago_matute_avila" />
            <figcaption>Ramón Santiago Matute Ávila</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ramon_santiago_matute_avila_02.jpg" alt="ramon_santiago_matute_avila" />
            <figcaption>Ramón Santiago Matute Ávila</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/ramon_santiago_matute_avila_03.jpg" alt="ramon_santiago_matute_avila" />
            <figcaption>Ramón Santiago Matute Ávila</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/rosalina_dominguez_00.jpg" alt="rosalina_dominguez" />
            <figcaption>Rosalina Domínguez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/rosalina_dominguez_01.jpg" alt="rosalina_dominguez" />
            <figcaption>Rosalina Domínguez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/rosalina_dominguez_02.jpg" alt="rosalina_dominguez" />
            <figcaption>Rosalina Domínguez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/rosalina_dominguez_04.jpg" alt="rosalina_dominguez" />
            <figcaption>Rosalina Domínguez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/sandra_maribel_sanchez_00.jpg" alt="sandra_maribel_sanchez" />
            <figcaption>Sandra Maribel Sánchez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/teresa_reyes_00.jpg" alt="teresa_reyes" />
            <figcaption>Teresa Reyes</figcaption>
          </figure>
        </Flex>
        <Flex className="activists-container">
          <figure>
            <StaticImage src="../images/honduras/aurelia_martinez_arzu_00.jpg" alt="aurelia_martinez_arzu" />
            <figcaption>Aurelia Martina Arzú</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/brenda_miranda_00.jpg" alt="brenda_miranda" />
            <figcaption>Brenda Miranda</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/rosalina_dominguez_03.jpg" alt="rosalina_dominguez" />
            <figcaption>Rosalina Domínguez</figcaption>
          </figure>
          <figure>
            <StaticImage src="../images/honduras/maria_dolores_euceda_cabrera_00.jpg" alt="maria_dolores_euceda_cabrera" />
            <figcaption>Rutilia Ical</figcaption>
          </figure>
        </Flex>
        <Themed.p><Themed.a as={Link} to={`/${dataFragments.fragment_4}`}>&#8592; {dataFragments.fragment_3}</Themed.a></Themed.p>
      </Box>
    </LayoutNoFullHeight>
  )

}

export default FotosActivistas

export const query = graphql`
  query FotosActivistas {
    dataJson(slug: {eq: "page-exposicion"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
    }
  }
`